export const keyboardShortcuts: {[combo: string]: () => void} = Object.create(null);

window.addEventListener("keydown", ev => {
	let name = "";
	if(ev.ctrlKey) {
		name += "ctrl+";
	}
	if(ev.altKey) {
		name += "alt+";
	}
	if(ev.metaKey) {
		name += "meta+";
	}
	if(ev.shiftKey) {
		name += "shift+";
	}
	name += ev.key;
	
	if(name in keyboardShortcuts) {
		ev.preventDefault();
		keyboardShortcuts[name]();
	}
});
