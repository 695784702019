import { EventEmitter } from "./eventemitter";

declare global {
	var channel: string|undefined;
	var channelSwitcher: EventEmitter<ChannelSwitcherEvents>;
}

type ChannelSwitcherEvents = {
	switch(channel: string|undefined): void,
}

window.channel = window.location.hash === "" ? undefined : window.location.hash.split("/")[0];
window.channelSwitcher = new EventEmitter<ChannelSwitcherEvents>();

window.addEventListener("hashchange", () => {
	const oldChannel = window.channel;
	if(window.location.hash === "") {
		window.channel = undefined;
	} else {
		window.channel = window.location.hash.split("/")[0];
	}
	if(window.channel !== oldChannel) {
		console.log("switching to channel", channel);
		window.channelSwitcher.emit("switch", window.channel);
	}
});
