import { Event } from "./types/event";

export function displayName(event: Event): string|undefined {
	if(typeof(event.profile?.name) === "string") {
		return event.profile.name;
	}
}

export function avatar(event: Event): string|undefined {
	if(typeof(event.profile?.avatar) === "string") {
		return event.profile.avatar;
	}
}
