import { Component } from "../../component"
import { h } from "../../dom";
import { getSettingBool } from "../../getsetting";
import { Event } from "../../types/event"
import { EventWrapper } from "./EventWrapper";
import { MessageEvent } from "./MessageEvent";
import { DeleteEvent, EditEvent, JoinEvent, LeaveEvent, ProfileEvent, ReactEvent, SummaryEvent } from "./text";
import { UnknownEvent } from "./UnknownEvent"

export type EventUpdate =
	| { type: "mounted" }
	| { type: "prev", data: Event }
	| { type: "refto", data?: Event }
	| { type: "ref", data: Event }
	| { type: "refdel", data: Event }
	| { type: "unread", data: boolean }

type EventDef = {
	hidden?: boolean|((event: Event) => boolean),
	create(el: HTMLDivElement, event: Event): Component<EventUpdate>,
};

const allShown = getSettingBool("show-all");
console.log("allShown", allShown);

const defaultEvt: EventDef = {
	hidden(event) {
		if(event.type.startsWith("m.")) {
			return false;
		}
		if(event.type.startsWith("r.")) {
			return !allShown;
		}
		return !allShown && !getSettingBool("show-unknown");
	},
	create(el, event) {
		if(event.type.startsWith("m.")) { // fallback for m. events
			return MessageEvent(el, event);
		}
		return UnknownEvent(el, event);
	},
};

export const eventTypes: {[type: string]: EventDef} = Object.assign(Object.create(null), {
	"e.typing": {
		hidden: true,
	},
	"r.delete": {
		hidden: !allShown,
		create: DeleteEvent,
	},
	"r.react": {
		hidden: !allShown,
		create: ReactEvent,
	},
	"r.edit": {
		hidden: !allShown,
		create: EditEvent,
	},
	"m.message": {
		create: MessageEvent,
	},
	"u.profile": {
		create: ProfileEvent,
	},
	"u.join": {
		create: JoinEvent,
	},
	"u.leave": {
		create: LeaveEvent,
	},
	"c.summary": {
		create: SummaryEvent,
	},
});

export function createEvent(event: Event, refs: Event[]): Component<EventUpdate>|undefined {
	if(event.id === undefined) {
		return undefined;
	}
	const def = eventTypes[event.type] || defaultEvt;
	
	if(def.hidden === true || (typeof(def.hidden) === "function" && def.hidden(event))) {
		return undefined;
	}
	
	return EventWrapper(event, refs, def.create(h("div", {className: "event-content"}), event));
}
