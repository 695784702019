import { getSettingBool } from "./getsetting";

export const safeTags: string[] = ['font', 'del', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'sup', 'sub', 'li', 'b', 'i', 'u', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption', 'pre', 'span', 'img', 'details', 'summary', 'marquee', 'ruby', 'rp', 'rt'];
export const safeProtocols: string[] = ['https:', 'http:'];
export const safeProps: {[tag: string]: string[]} = {
	font: ['color', 'bg-color'],
	span: ['color', 'bg-color'],
	a: ['name', 'target', 'href'],
	img: ['width', 'height', 'alt', 'title', 'src', 'ismap'],
	ol: ['start'],
	code: ['class']
};

function is<T>(_: any): asserts _ is T {}

// takes text and inserts it as html
// doing all the necessary filtering and transforming
export function insertHTML(target: HTMLElement, html: string) {
	const template = document.createElement("template");
	template.innerHTML = html; // setting template innerHTML is safe
	const walker = document.createTreeWalker(template.content);
	
	const urls = [];
	
	let node;
	while((node = walker.nextNode()) !== null) {
		switch(node.nodeType) {
			case node.TEXT_NODE: { continue; } // text is valid
			case node.ELEMENT_NODE: { // filter elements
				is<HTMLElement>(node);
				const nodeName = node.nodeName.toLowerCase();
				
				// unsafe nodes get replaced with their own children
				if(safeTags.indexOf(nodeName) < 0) {
					walker.previousNode(); // so the next iteration won't skip over the children
					
					while(node.childNodes.length) {
						// insert the children adjacent to the node
						node.parentNode!.insertBefore(node.childNodes[0], node);
					}
					// remove the node
					node.parentNode!.removeChild(node);
					continue;
				}
				
				// filter attributes
				// walk backwards so that removed attributes don't affect i
				for(let i = node.attributes.length - 1; i >= 0; i--) {
					if(!(nodeName in safeProps) || safeProps[nodeName].indexOf(node.attributes[i].name) < 0) {
						node.removeAttribute(node.attributes[i].name);
					}
				}
				
				// process attributes
				for(let i = 0; i < node.attributes.length; i++) {
					const attr = node.attributes[i];
					switch(attr.name) {
						case "bg-color": { // turn into background-color css
							node.style.backgroundColor = attr.value;
							break;
						}
						case "color": { // turn into color css
							node.style.color = attr.value;
							break;
						}
						case "class": { // for code highlighting, indicating the language. has to be a single class (no spaces) and start with language-
							if(!attr.value.startsWith("language-") || attr.value.indexOf(" ") < 0){
								attr.value = "";
							}
							break;
						}
						case "src": {
							if(!getSettingBool("show-media")) {
								attr.value = "";
							}
							break;
						}
						case "width":
						case "height": {
							let num = parseInt(attr.value);
							if(!isNaN(num)) {
								num = Math.min(num, 512);
								attr.value = num.toString();
							}
							break;
						}
						case "href": { // links must be using a safe protocol
							try {
								const notjusthref = node.childNodes.length > 1 ||
									node.childNodes[0].nodeType !== node.TEXT_NODE ||
									node.childNodes[0].nodeValue !== attr.value;
								
								if(attr.value.startsWith("#") && attr.value.substring(1).indexOf("@") >= 0) { // channel link
									if(notjusthref) {
										node.dataset.domain = attr.value;
									}
								} else if(attr.value.startsWith("@") && attr.value.substring(1).indexOf("@") >= 0) { // user link
									if(notjusthref) {
										node.dataset.domain = attr.value;
									}
								} else {
									const url = new URL(attr.value);
									if(safeProtocols.indexOf(url.protocol) < 0) {
										attr.value = "";
									} else {
										urls.push(url);
										if(notjusthref) {
											node.dataset.domain = url.host;
										}
									}
								}
							} catch {
								attr.value = "";
							}
							break;
						}
					}
				}
				
				if(nodeName === "a") {
					is<HTMLAnchorElement>(node);
					node.rel = "noopener";
					node.target = "_blank";
				}
				
				if(nodeName === "img") {
					is<HTMLImageElement>(node);
					node.loading = "lazy";
					node.referrerPolicy = "no-referrer";
				}
				
				break;
			}
			default: { // types like comments, cdata, and some that won't ever be actually encountered
				node.parentNode!.removeChild(node);
				break;
			}
		}
	}
	
	// move the contents of the template into the target
	target.appendChild(template.content);
}

// autolinks text
export function insertText(target: HTMLElement, text: string) {
	const linker = new RegExp('\\b' +
		'https?' +
		'://' +
		'(' + // server name
			'(' +
			'(\\d{1,3}\\.){3}\\d{1,3}|' + // ipv4 address
			'\\[[0-9a-f:\\.]{2,45}\\]|' + // ipv6 address
			'[0-9a-z\\-]+(\\.[0-9a-z\\-]+)+' + // dns name
			')(:\\d{1,5})?' + // port
		')' +
		'(/[A-Za-z0-9\\-\\._~!\\$&\'\\(\\)\\*\\+\\,;=:@%]+)*/?' + // path (https://datatracker.ietf.org/doc/html/rfc3986#section-3.3)
		'(\\?[A-Za-z0-9\\-\\._~!\\$&\'\\(\\)\\*\\+\\,;=:@%/\\?]+)?' + // query
		'(#[A-Za-z0-9\\-\\._~!\\$&\'\\(\\)\\*\\+\\,;=:@%/\\?]+)?' + // hash
		'(?=$|[\\b\\s]|[^0-9a-z\\-])', // end
		'ig'
	);
	
	let urls = [];
	
	let match;
	let last = 0;
	while((match = linker.exec(text)) !== null) {
		const before = text.substring(last, match.index);
		const link = match[0];
		last = linker.lastIndex;
		urls.push(link);
		
		target.appendChild(document.createTextNode(before));
		const a = target.appendChild(document.createElement("a"));
		a.textContent = a.href = link;
		a.target = "_blank";
		a.rel = "noopener";
	}
	
	target.appendChild(document.createTextNode(text.substring(last)));
}
