import { EventUpdate } from ".";
import { Component } from "../../component";
import { append, h } from "../../dom";
import { array, invalid } from "../../format";
import { Event } from "../../types/event";
import { setAvatar } from "../../ui/common/avatar";
import { renderBody } from "../../ui/common/body";
import { userHeader } from "../../ui/common/displayName";
import { Time } from "./Time";

function* MessageEventHeader(event: Event): Component {
	const avatar = h("div", {className: "avatar"});
	setAvatar(avatar, typeof(event.profile?.icon) === "string" ? event.profile.icon : undefined, event.user);
	
	const time = Time(event.date);
	
	const el = h("div", {className: "user-container"}, [
		avatar,
		userHeader(event),
		time.next().value!,
	]);
	
	while(yield el);
	
	time.next();
}

export function* MessageEvent(el: HTMLDivElement, event: Event): Component<EventUpdate> {
	let header: Component|undefined = MessageEventHeader(event);
	let headerEl: Node;
	
	let content: HTMLDivElement;
	append(el, [
		(headerEl = header.next().value!),
		(content = h("div", {className: "content"}))
	]);
	
	renderBody(content, event.content?.body);
	
	if(event.refs?.edit) {
		content.dataset.edited = "true";
	}
	
	if(!invalid(event.content?.mentions, array("string"))) {
		const m = event.content.mentions as string[];
		if(m.includes(login.user)) {
			content.dataset.mentionsme = "true";
		}
	}
	
	let signal: EventUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "prev") {
			// remove the username and avatar for continuations
			const e = signal.data;
			if(
				header &&
				e.type === event.type && // also a message
				e.user === event.user && // by the same user
				Math.abs(event.date - e.date) < 5*60*1000 && // within 5 minutes
				event.ref === undefined // this one isn't a reply
			) {
				el.parentElement!.dataset.continuation = "true";
				header?.next();
				header = undefined;
				el.removeChild(headerEl);
			}
		}
		
		if(signal.type === "ref") {
			const e = signal.data;
			if(e.type === "r.edit") {
				event.content = e.content;
				content.className = "content";
				content.innerHTML = "";
				renderBody(content, event.content?.body);
				content.dataset.edited = "true";
				
				// does the new event mention me
				delete content.dataset.mentionsme;
				if(!invalid(event.content?.mentions, array("string"))) {
					const m = event.content.mentions as string[];
					if(m.includes(login.user)) {
						content.dataset.mentionsme = "true";
					}
				}
			}
		}
	}
	
	header?.next();
}
