import { h } from "../../dom";
import { Event } from "../../types/event";
import { getDisplayName } from "../../util/displayName";
import { setAvatar } from "./avatar";

function userName(el: HTMLElement, ev: Event) {
	const d = getDisplayName(ev);
	if(d !== undefined) {
		el.appendChild(h("span", {className: "user-name"}, [h("bdi", {}, [d])]));
	} else {
		el.appendChild(h("span", {className: "user-address"}, [ev.user]));
	}
}

export function userInline(ev: Event) {
	const el = h("span", {className: "user-inline", title: ev.user});
	
	const avatar = h("div", {className: "avatar"});
	setAvatar(avatar, typeof(ev.profile?.icon) === "string" ? ev.profile.icon : undefined, ev.user);
	el.appendChild(avatar);
	
	userName(el, ev);
	
	return el;
}

export function userHeader(ev: Event) {
	const el = h("span", {className: "user", title: ev.user});
	
	userName(el, ev);
	
	return el;
}
