import { WsState } from "../ws";
import { setTitleOffline } from "./title";

const states: Record<WsState, string> = {
	connecting: "[offline]",
	connected: "",
	offline: "[offline]",
}

let cancel: (() => void)|undefined;
ws.on("state", state => {
	cancel?.();
	let cancelled = false;
	cancel = () => cancelled = true;
	setTimeout(() => {
		if(!cancelled) {
			setTitleOffline(states[state]);
		}
	}, 500);
});
