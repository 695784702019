import { Component } from "../../component";
import { h } from "../../dom";
import { EventUpdate } from "./index";
import { Event } from "../../types/event";
import { Reaction, ReactionUpdate } from "./Reaction";
import { EventRef, EventRefUpdate } from "./EventRef";
import { EventActions } from "./EventActions";

export function* EventWrapper(event: Event, refs: Event[], inner: Component<EventUpdate>): Component<EventUpdate> {
	// an indicator of the message this is in relation to
	let evtRef: Component<EventRefUpdate>|undefined;
	if(event.ref !== undefined) {
		evtRef = EventRef(event);
	}
	
	const actions = EventActions(event, refs);
	
	let reactionEl: HTMLDivElement;
	const el = h("li", {
		className: "event",
		"data-type": event.type,
		"data-user": event.user,
		"data-id": event.id?.toString(),
		"data-me": event.user === login.user,
		"data-ref": event.ref?.toString(),
		id: event.channel.substring(1) + "/event/" + event.id!,
	}, [
		actions.next().value!,
		evtRef?.next()?.value!,
		inner.next().value!,
		(reactionEl = h("div", {className: "event-reactions"})),
	]);
	
	// keep track of reactions
	let reactions: {[reaction: string]: {
		comp: Component<ReactionUpdate>,
		node: Node
	}} = Object.create(null);
	
	function addReactionEvent(evt: Event) {
		if(typeof(evt.content?.reaction) !== "string") {
			return;
		}
		const key = evt.content.reaction;
		
		if(!(key in reactions)) {
			// make a new Reaction component for the key if it doesn't exist
			const comp = Reaction(event, key, () => {
				// when it has no reactions, remove it
				reactionEl.removeChild(node);
				setTimeout(() => comp.next());
				if(reactions[key]?.comp === comp) {
					delete reactions[key];
				}
			});
			const node = reactionEl.appendChild(comp.next().value!);
			
			reactions[key] = {
				comp,
				node,
			};
		}
		
		const r = reactions[key];
		r.comp.next({type: "add", data: evt});
	}
	refs.filter(n => n.type === "r.react").forEach(addReactionEvent);
	
	function removeReactionEvent(evt: Event) {
		if(typeof(evt.content?.reaction) !== "string") {
			return;
		}
		const key = evt.content.reaction;
		if(!(key in reactions)) { // nobody reacted with this
			return;
		}
		
		const r = reactions[key];
		r.comp.next({type: "remove", data: evt});
	}
	
	let signal: EventUpdate|undefined;
	while(signal = yield el) {
		const s = signal;
		
		if(s.type === "prev") { // the previous event
			const thisdate = new Date(event.date);
			const prevdate = new Date(s.data.date);
			if(
				thisdate.getFullYear() !== prevdate.getFullYear() ||
				thisdate.getMonth() !== prevdate.getMonth() ||
				thisdate.getDate() !== prevdate.getDate()
			) {
				// mark that this event occurs on a different day than the previous one
				el.dataset.newday = (thisdate.getMonth() + 1) + "/" + thisdate.getDate();
			}
		}
		
		if(s.type === "ref") {
			// a new event got added that refers to this
			refs.push(s.data);
			
			// ensure event.refs is up to date
			event.refs = event.refs || Object.create(null);
			event.refs![s.data.type] = (event.refs![s.data.type] || 0) + 1;
			
			if(s.data.type === "r.react") {
				addReactionEvent(s.data);
			}
		}
		if(s.type === "refdel") {
			// an event that refers to this got deleted
			const index = refs.findIndex(n => n.id === s.data.id);
			if(index < 0) {
				continue;
			}
			const evt = s.data;
			refs.splice(index, 1);
			
			// ensure event.refs is up to date
			if(event.refs?.[evt.type]) {
				event.refs[evt.type]--;
				if(event.refs[evt.type] <= 0) {
					delete event.refs[evt.type];
				}
			}
			if(evt.type === "r.react") {
				removeReactionEvent(evt);
			}
		}
		
		if(s.type === "refto") {
			// the event that this refers to got loaded, pass it on to evtRef
			if(evtRef) {
				evtRef.next({type: "load", data: s.data});
			}
		}
		
		if(s.type === "unread") {
			// sets the unread status of the event
			if(s.data) {
				el.dataset.unread = "true";
			} else {
				delete el.dataset.unread;
			}
		}
		
		inner.next(signal);
	}
	
	actions.next();
	inner.next();
	if(evtRef) {
		evtRef.next();
	}
}
