import { Component } from "../component";
import { append, h } from "../dom";
import { ChannelMember } from "../types/member";
import { setAvatar } from "../ui/common/avatar";

export type MemberEntryUpdate =
	| {type: "update", data: ChannelMember}

export function* MemberEntry(member: ChannelMember): Component<MemberEntryUpdate> {
	const avatar = h("div", {className: "avatar"});
	const role = h("bdi");
	const user = h("div", {className: "member-user"});
	
	const el = h("li", {className: "member", title: member.user}, [
		avatar,
		h("div", {className: "member-text"}, [
			user,
			h("span", {className: "member-role"}, [role]),
		]),
	]);
	
	function update(mem: ChannelMember, initial?: boolean) {
		const old = member;
		member = mem;
		
		if(initial || old.role !== member.role) {
			role.textContent = member.role;
			el.dataset.role = member.role;
		}
		
		if(
			initial ||
			old.profile?.name !== member.profile?.name ||
			old.profile?.icon !== member.profile?.icon
		) {
			user.innerHTML = "";
			avatar.innerHTML = "";
			
			setAvatar(avatar, typeof(member.profile?.icon) === "string" ? member.profile.icon : undefined, member.user);
			
			let name: string = "";
			if(typeof(member.profile?.name) === "string") {
				name = member.profile.name;
			}
			
			append(user, [
				name ? h("span", {className: "member-name"}, [h("bdi", {}, [name])]) : undefined,
				h("span", {className: "member-address"}, [member.user]),
			]);
		}
	}
	
	update(member, true);
	
	let signal: MemberEntryUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "update") {
			update(signal.data);
		}
	}
}
