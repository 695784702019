import { Component } from "../../component";
import { h } from "../../dom";
import { Event } from "../../types/event";
import { inlineEvent } from "../../ui/common/inline";

export type EventRefUpdate =
	| {type: "load", data?: Event}

export function* EventRef(event: Event): Component<EventRefUpdate> {
	const el = h("a", {
		className: "event-ref",
		href: "#" + event.channel.substring(1) + "/event/" + event.ref!,
	}, ["..."]);
	
	let signal: EventRefUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "load") {
			const e = signal.data;
			if(e) {
				inlineEvent(el, e);
			} else {
				el.textContent = "...";
			}
		}
	}
}
