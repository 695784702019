import { append } from "../../dom";
import { Event } from "../../types/event";
import { userInline } from "./displayName";

function defaultEvtInline(el: HTMLElement, event: Event) {
	append(el, [
		userInline(event),
		": ",
		event.type,
	]);
}

const evtInline: [string|((event: Event) => boolean), (el: HTMLElement, event: Event) => void][] = [
	[e => e.type.startsWith("m."), (el, event) => {
		let text = "";
		let full = false;
		
		if(typeof(event.content?.body?.["text/plain"]) === "string") {
			text = event.content.body["text/plain"];
			if(text.split("\n").length === 1) {
				full = true;
			} else {
				text = text.split("\n")[0];
			}
		}
		append(el, [
			userInline(event),
			": ",
			text + (full ? "" : "..."),
		]);
	}],
	["r.react", (el, event) => {
		append(el, [
			userInline(event),
			" reacted to an event",
		]);
	}],
	["r.delete", (el, event) => {
		append(el, [
			userInline(event),
			" deleted an event"
		])
	}],
	["u.profile", (el, event) => {
		append(el, [
			userInline(event),
			" set their profile"
		])
	}],
	["u.join", (el, event) => {
		append(el, [
			userInline(event),
			" joined the channel"
		])
	}],
	["u.leave", (el, event) => {
		append(el, [
			userInline(event),
			" left the channel"
		])
	}],
];

export function inlineEvent(el: HTMLElement, event: Event) {
	let inline = defaultEvtInline;
	for(let [predicate, handler] of evtInline) {
		if(typeof(predicate) === "string") {
			if(predicate === event.type) {
				inline = handler;
				break;
			}
		} else {
			if(predicate(event)) {
				inline = handler;
				break;
			}
		}
	}
	el.innerHTML = "";
	inline(el, event);
}
