let prefix = "";
let offline = "";
let main = "";
let suffix = "Swerve";

function update() {
	document.title = (offline || prefix) + (offline || prefix ? " " : "") + main + (main ? " | " : "") + suffix;
}

export function setTitleMain(s: string) {
	main = s;
	update();
}

export function setTitlePrefix(s: string) {
	prefix = s;
	update();
}

export function setTitleOffline(s: string) {
	offline = s;
	update();
}