import { id } from "../dom";
import { Event } from "../types/event";
import { inlineEvent } from "./common/inline";

const el = id("reply")!;

const replies: {[channel: string]: Event} = Object.create(null);

export let reply: Event|undefined;
export const setReply = (event: Event) => {
	replies[event.channel] = event;
	update();
};
export const clearReply = (ch?: string) => {
	if(ch === undefined) {
		ch = channel;
	}
	if(ch === undefined) {
		return;
	}
	delete replies[ch];
	update();
};
el.onclick = () => clearReply();

channelSwitcher.on("switch", () => {
	update();
});
update();

function update() {
	if(channel !== undefined && channel in replies) {
		const event = replies[channel];
		reply = event;
		inlineEvent(el, event);
		el.dataset.filled = "true";
	} else {
		reply = undefined;
		el.innerHTML = "";
		delete el.dataset.filled;
	}
}
