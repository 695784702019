import { Component } from "../../component";
import { append, h } from "../../dom";
import { Event } from "../../types/event";
import { userInline } from "../../ui/common/displayName";
import { EventUpdate } from "./index";

export function* UnknownEvent(el: HTMLDivElement, event: Event): Component<EventUpdate> {
	append(el, [
		userInline(event),
		": ",
		h("span", {className: "event-type"}, [event.type]),
		":",
		h("br"),
		h("code", {}, [JSON.stringify(event.content, null, 2)]),
	]);
	
	while(yield el);
}
