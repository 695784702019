import { Component } from "../../component";
import { h } from "../../dom";
import { Event } from "../../types/event";

export type ReactionUpdate =
	| {type: "add", data: Event}
	| {type: "remove", data: Event}

export function* Reaction(event: Event, key: string, onremove?: () => void): Component<ReactionUpdate> {
	let users: {[user: string]: Event[]} = Object.create(null);
	
	let countText: HTMLSpanElement, count = 0;
	
	function toggle() {
		if(login.user in users) {
			send(event.channel, "delete", {}, users[login.user][0].id);
		} else {
			send(event.channel, "react", {reaction: key}, event.id);
		}
	}
	
	const el = h("div", {className: "reaction", onclick: toggle}, [
		h("span", {className: "reaction-key"}, [key]),
		(countText = h("span", {className: "reaction-count"}, ["0"]))
	]);
	
	let signal: ReactionUpdate|undefined;
	while((signal = yield el) !== undefined) {
		const s = signal;
		if(s.type === "add") {
			const u = s.data.user;
			if(!(u in users)) {
				users[u] = [];
				
				// this user reacted, mark it
				if(u === login.user) {
					el.dataset.me = "true";
				}
			}
			users[u].push(s.data);
		}
		
		if(s.type === "remove") {
			const u = s.data.user;
			if(u in users) {
				users[u] = users[u].filter(n => n.id !== s.data.id);
				if(users[u].length === 0) {
					delete users[u];
					
					// this user deleted a reaction, unmark it
					if(u === login.user) {
						delete el.dataset.me;
					}
				}
			}
		}
		
		const userlist = Object.keys(users);
		if(!userlist.length) {
			// tell the parent to remove this element
			onremove?.();
		}
		
		// keep count up to date
		count = userlist.length;
		countText.textContent = count.toString();
		
		// hover text should be a list of users that reacted
		el.title = userlist.join(", ");
		
		// data-me should reflect whether this user has reacted
		if(users[login.user]) {
			el.dataset.me = "true";
		} else {
			delete el.dataset.me;
		}
	}
}
