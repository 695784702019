import { Component } from "../../component";
import { h } from "../../dom";

export function* Time(time: number): Component {
	const ts = new Date(time);
	const hour = ts.getHours();
	const minutes = ts.getMinutes();
	const pm = hour >= 12;
	const formattedDate = (ts.getMonth() + 1) + "/" + ts.getDate() + " " + ((hour % 12) || 12) + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + (pm ? 'PM' : 'AM');
	
	const el = h("time", {title: ts.toLocaleString(), datetime: ts.toISOString()}, [formattedDate]);
	
	while(yield el);
}
