import { Component } from "../component";
import { id } from "../dom";
import { ChannelMembership, channelMembershipFormat } from "../types/channel";
import { ChannelEntry, ChannelEntryUpdate } from "./ChannelEntry";
import { List, ListDef } from "./List";

const scrollParent = id("channels")!;

export type ChannelListEvents =
	| {type: "mount"}
	| {type: "unmount"}
	| {type: "channel", data: ChannelMembership}
	| {type: "channeldel", data: string}
	| {type: "select", data: string|undefined}

export function* ChannelList(category?: string, order?: string, onchannellist?: (channels: ChannelMembership[], load?: () => void) => void): Component<ChannelListEvents> {
	let endpoint = "/v1/account/";
	if(category === "") {
		endpoint += "uncategorized";
	} else if(typeof(category) === "string") {
		endpoint += "categories/" + encodeURIComponent(category);
	} else {
		endpoint += "channels";
	}
	
	// for sorting channels
	function compare(a: ChannelMembership, b: ChannelMembership) {
		if(order === "recent") {
			return (a.latest||0) - (b.latest||0);
		}
		return a.channel > b.channel ? 1 :
			(b.channel > a.channel ? -1 : 0);
	}
	
	type Def = ListDef<ChannelMembership, ChannelEntryUpdate>;
	
	let channelList: Def[] = [];
	
	// which channel is focused (if it's in this category)
	let selected: Def|undefined;
	
	const list = List({
		title: "channel (category " + JSON.stringify(category) + ")",
		loadmore: "Load more channels",
		classPrefix: "channel-list",
		compare,
		endpoint,
		format: channelMembershipFormat,
		idKey: "channel",
		Item: ChannelEntry,
		args: [],
		scrollParent,
		onlist: (channels, load) => {
			channelList = channels;
			onchannellist?.(channels.map(n => n.data), load);
			setSelected();
		},
		order,
		reorder: order === "recent",
	});
	const el = list.next().value!;
	
	// the selected channel may be a buffered update from mashing alt+down or something, rather than `window.channel`
	let ch: string|undefined = channel;
	function setSelected() {
		const oldSelected = selected;
		selected = channelList.find(n => n.data.channel === ch);
		
		if(oldSelected === selected) {
			return;
		}
		if(oldSelected) {
			oldSelected.comp.next({type: "select", data: false});
		}
		if(selected) {
			selected.comp.next({type: "select", data: true});
		}
	}
	
	setSelected();
	
	let signal: ChannelListEvents|undefined;
	while(signal = yield el) {
		if(signal.type === "mount" || signal.type === "unmount") {
			list.next(signal);
		}
		
		if(signal.type === "channel") {
			list.next({type: "item", data: signal.data});
		}
		
		if(signal.type === "channeldel") {
			list.next({type: "delete", data: signal.data});
		}
		
		if(signal.type === "select") {
			ch = signal.data;
			setSelected();
		}
	}
	
	list.next();
}
