import { Component } from "../component";
import { check } from "../format";
import { ChannelMembership, channelMembershipFormat } from "../types/channel";
import { getChannelFromSidebar } from "../ui/channels";
import { setSummary } from "../ui/header";
import { AddChannel } from "./AddChannel";
import { EventTimeline, EventTimelineMessage } from "./EventTimeline";
import { MemberList, MemberListUpdate } from "./MemberList";

type ChannelSwitcherDef = {
	timeline: {
		comp: Component<EventTimelineMessage>,
		node: Node,
	},
	members: {
		comp: Component<MemberListUpdate>,
		node: Node,
	},
	channel?: Promise<ChannelMembership>
};

export function* ChannelSwitcher(el: HTMLElement, membersEl: HTMLElement, channelSettings: HTMLAnchorElement): Component {
	let channels: {[key: string]: ChannelSwitcherDef} = Object.create(null);
	let mounted: ChannelSwitcherDef|"default"|undefined;
	
	const defaultPage = AddChannel();
	const defaultPageEl = defaultPage.next().value!;
	
	const unsubs: (() => void)[] = [];
	
	function switchChannels() {
		channelSettings.href = channelSettings.href.replace(/(\?.*)?$/, "?" + (channel?.substring(1) || ""));
		if(channel) {
			channelSettings.style.display = "";
			
			let ch: ChannelSwitcherDef;
			if(!(channel in channels)) {
				const timelinecomp = EventTimeline(channel);
				const timelinenode = timelinecomp.next().value!;
				
				const memberscomp = MemberList(channel);
				const membersnode = memberscomp.next().value!;
				
				ch = channels[channel] = {
					timeline: {
						comp: timelinecomp,
						node: timelinenode,
					},
					members: {
						comp: memberscomp,
						node: membersnode,
					}
				};
			} else {
				ch = channels[channel];
			}
			
			if(mounted && mounted !== "default") {
				mounted.timeline.comp.next({type: "unmount"});
				mounted.members.comp.next({type: "unmount"});
				el.removeChild(mounted.timeline.node);
				membersEl.removeChild(mounted.members.node);
			} else if(mounted === "default") {
				defaultPage.next({type: "unmount"});
				el.removeChild(defaultPageEl);
			}
			mounted = ch;
			el.appendChild(ch.timeline.node);
			ch.timeline.comp.next({type: "mount"});
			membersEl.appendChild(ch.members.node);
			ch.members.comp.next({type: "mount"});
			
			if(!ch.channel) {
				const fromSidebar = getChannelFromSidebar(channel);
				if(fromSidebar) {
					ch.channel = Promise.resolve(fromSidebar);
				} else {
					ch.channel = api("/v1/account/channels/" + encodeURIComponent(channel.substring(1)))
						.then(check<ChannelMembership>(channelMembershipFormat));
				}
			}
			
			ch.channel.then(mem => {
				ch.timeline.comp.next({type: "channel", data: mem});
				if(mounted === ch) {
					setSummary(mem.channel, mem.summary);
				}
			});
		} else {
			channelSettings.style.display = "none";
			setSummary(undefined, undefined);
			if(mounted && mounted !== "default") {
				mounted.timeline.comp.next({type: "unmount"});
				el.removeChild(mounted.timeline.node);
				mounted.members.comp.next({type: "unmount"});
				membersEl.removeChild(mounted.members.node);
			}
			
			if(mounted !== "default") {
				el.appendChild(defaultPageEl);
				defaultPage.next({type: "mount"});
				mounted = "default";
			}
		}
	}
	switchChannels();
	
	unsubs.push(channelSwitcher.on("switch", switchChannels));
	
	unsubs.push(ws.on("message", ms => {
		if(ms.type === "event" && ms.data.channel in channels) {
			channels[ms.data.channel].timeline.comp.next({type: "event", data: ms.data});
			
			const e = ms.data;
			if(e.type === "u.join" || e.type === "u.profile") {
				channels[ms.data.channel].members.comp.next({type: "member", data: e.user});
			}
			if(e.type === "u.leave") {
				channels[ms.data.channel].members.comp.next({type: "memberdel", data: e.user});
			}
			if((e.type === "u.kick" || e.type === "u.perm") && typeof(e.content?.user) === "string") {
				channels[ms.data.channel].members.comp.next({type: "memberdel", data: e.content.user});
			}
		}
		
		if(ms.type === "channel" && ms.data.channel in channels) {
			channels[ms.data.channel].channel = Promise.resolve(ms.data);
			setSummary(ms.data.channel, ms.data.summary);
			channels[ms.data.channel].timeline.comp.next({type: "channel", data: ms.data});
		}
	}));
	
	unsubs.push(ws.on("reconnect", () => {
		for(const ch in channels) {
			channels[ch].timeline.comp.next();
			channels[ch].members.comp.next();
		}
		channels = {};
		switchChannels();
	}));
	
	while(yield el) {}
	
	unsubs.forEach(n => n());
	for(const ch in channels) {
		channels[ch].timeline.comp.next();
		channels[ch].members.comp.next();
	}
}
