import { Component } from "../../component";
import { append, h } from "../../dom";
import { Event } from "../../types/event";
import { userInline } from "../../ui/common/displayName";
import { EventUpdate } from "./index";

function text(t: string) {
	return function*(el: HTMLDivElement, event: Event): Component<EventUpdate> {
		append(el, [
			userInline(event),
			" " + t,
		]);
		
		while(yield el);
	}
}

export const ProfileEvent = text("set their profile");
export const JoinEvent = text("joined the channel");
export const LeaveEvent = text("left the channel");
export const SummaryEvent = text("set the channel summary");
export const EditEvent = text("edited an event");
export const DeleteEvent = text("deleted an event");

export function* ReactEvent(el: HTMLDivElement, event: Event): Component<EventUpdate> {
	const valid = typeof(event.content?.reaction) === "string";
	
	append(el, [
		userInline(event),
		valid ? " reacted with " : " reacted",
		valid ? h("bdi", {}, [event.content.reaction]) : undefined,
	]);
	
	while(yield el);
}
