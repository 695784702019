import { Component } from "../component";
import { id } from "../dom";
import { check } from "../format";
import { ChannelMember, channelMemberFormat } from "../types/member";
import { List } from "./List";
import { MemberEntry } from "./MemberEntry";

const scrollParent = id("members")!;

export type MemberListUpdate =
	| {type: "mount"}
	| {type: "unmount"}
	| {type: "member", data: string}
	| {type: "memberdel", data: string}

export function* MemberList(channel: string): Component<MemberListUpdate> {
	function compare(a: ChannelMember, b: ChannelMember) {
		return a.user > b.user ? 1 :
			(b.user > a.user ? -1 : 0);
	}
	
	const list = List({
		title: "member",
		loadmore: "Load more users",
		classPrefix: "member-list",
		compare,
		endpoint: "/v1/channels/" + encodeURIComponent(channel.substring(1)) + "/members",
		format: channelMemberFormat,
		idKey: "user",
		Item: MemberEntry,
		args: [],
		scrollParent,
	});
	const el = list.next().value!;
	
	let signal: MemberListUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "mount" || signal.type === "unmount") {
			list.next(signal);
		}
		
		if(signal.type === "memberdel") {
			list.next({type: "delete", data: signal.data});
		}
		
		if(signal.type === "member") {
			// load it
			api("/v1/channels/" + encodeURIComponent(channel.substring(1)) + "/members/" + encodeURIComponent(signal.data.substring(1)))
				.then(check<ChannelMember>(channelMemberFormat))
				.then(member => {
					list.next({type: "item", data: member});
				});
		}
	}
	
	list.next();
}
