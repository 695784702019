import { id } from "../dom";
import { array, invalid } from "../format";
import { setAvatar } from "./common/avatar";
import { setTitleMain } from "./title";

const channelAvatar = id("channel-avatar")!;
const channelName = id("channel-name")!;
const channelAddress = id("channel-address")!;
const channelTopic = id("channel-topic")!;

export function setSummary(channel: string|undefined, summary?: any) {
	if(channel === undefined) {
		delete document.body.dataset.channel;
	} else {
		document.body.dataset.channel = channel;
	}
	
	channelAvatar.innerHTML = "";
	setAvatar(channelAvatar, typeof(summary?.icon) === "string" ? summary.icon : undefined, channel || "");
	channelAddress.textContent = channel || "";
	
	let name: string = "";
	if(typeof(summary?.name) === "string") {
		name = summary.name;
	}
	
	if(channel?.startsWith("#dm:")) {
		const v = invalid(summary?.users, array("string"));
		if(v === undefined) {
			name = (summary.users as string[]).filter(n => n !== login.user).join(", ");
		}
	}
	
	channelName.firstElementChild!.textContent = name;
	if(name === "") {
		channelName.dataset.empty = "true";
	} else {
		delete channelName.dataset.empty;
	}
	setTitleMain(name || channel || "");
	
	const topic = typeof(summary?.topic) === "string" ? summary.topic : "";
	channelTopic.firstElementChild!.textContent = topic.split("\n")[0] === topic ? topic : topic.split("\n")[0] + "...";
	if(topic === "") {
		channelTopic.dataset.empty = "true";
	} else {
		delete channelTopic.dataset.empty;
	}
	channelTopic.onclick = () => alert(topic);
}
