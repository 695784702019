import md, { HtmlOutputRule, HtmlRules, MatchFunction, Output, ParserRule } from "@khanacademy/simple-markdown";
import { safeTags } from "./html";

// a ruleset for simple-markdown

const firstOnLineMatchRule = (regex: RegExp): MatchFunction => (source, state) => {
	if(!state.prevCapture || (Array.isArray(state.prevCapture) && /^$|\n$/.test(state.prevCapture[0]))) {
		return regex.exec(source);
	}
}

// there isn't really a mechanism in simple-markdown to return extra state after evaluating the rules
// so i'm using a module-scoped variable that gets reset after every markdown() call
let mentions: Set<string>|undefined;

let markdownRules: Omit<HtmlRules, "Array"> = {
	...md.defaultRules,
	Array: undefined as unknown as ParserRule&HtmlOutputRule, // types are weird
	paragraph: {
		...md.defaultRules.paragraph, // don't end on double newlines
		match: md.blockRegex(/^((([^\n]|\n(?! *\n))+)(\n *)*)/),
		html(node, output) { // don't make any elements for paragraphs
			return output(node.content);
		}
	},
	text: {
		...md.defaultRules.text,
		match: md.anyScopeRegex( // break text token on single newline as well
			/^[\s\S]+?(?=[^0-9A-Za-z\s\u00c0-\uffff]|\n| {2,}\n|\w+:\S|$)/
		)
	},
	newline: { // all newlines should <br />
		...md.defaultRules.newline,
		match: md.anyScopeRegex(/^\n/),
		html() {
			return "<br />";
		}
	},
	mention: {
		order: md.defaultRules.text.order,
		match: source => new RegExp('^' + 
			'@\\/?([a-zA-Z0-9\\-\\.:=_]{1,64})' + // localpart
			'@' +
			'(' + // server name
				'(' +
				'(\\d{1,3}\\.){3}\\d{1,3}|' + // ipv4 address
				'\\[[0-9a-f:\\.]{2,45}\\]|' + // ipv6 address
				'[0-9a-z\\-\\.]{1,255}' + // dns name
				')(:\\d{1,5})?' + // port
			')(?=$|[\b\s]|[^0-9a-z\-\.])', // end
			'i'
		).exec(source),
		parse(capture, _parse, state) {
			let user = capture[0];
			if(!user.startsWith("@/")) {
				// add this mention to the set of mentions
				if(!mentions) {
					mentions = new Set();
				}
				mentions.add(user);
				console.log("adding mention", user);
			} else {
				user = user.substring(0, 1) + user.substring(2);
			}
			return {
				content: user
			}
		},
		html(node) {
			return md.htmlTag("a", md.sanitizeText(node.content), { href: node.content });
		}
	},
	channel: {
		order: md.defaultRules.text.order,
		match: source => new RegExp('^' + 
			'#([a-zA-Z0-9\\-\\.:=_]{1,64})' + // localpart
			'@' +
			'(' + // server name
				'(' +
				'(\\d{1,3}\\.){3}\\d{1,3}|' + // ipv4 address
				'\\[[0-9a-f:\\.]{2,45}\\]|' + // ipv6 address
				'[0-9a-z\\-\\.]{1,255}' + // dns name
				')(:\\d{1,5})?' + // port
			')(?=$|[\b\s]|[^0-9a-z\-\.])', // end
			'i'
		).exec(source),
		parse(capture) {
			return {
				content: capture[0]
			}
		},
		html(node) {
			return md.htmlTag("a", md.sanitizeText(node.content), { href: node.content });
		}
	},
	heading: {
		...md.defaultRules.heading,
		match: firstOnLineMatchRule(/^ *(#{1,6})([^\n]+?)#* *(?:\n *)+/),
	},
	hr: {
		...md.defaultRules.hr,
		match: firstOnLineMatchRule(/^( *[-*_]){3,} *(?:\n *)+/),
	},
	codeBlock: {
		order: md.defaultRules.codeBlock.order,
		match: source => /^```(?:([a-z0-9]+?))?\n([\s\S]*?)\n?```/i.exec(source),
		parse(capture) {
			return {
				content: capture[2],
				lang: capture[1]
			}
		},
		html(node) {
			return md.htmlTag("pre",
				md.htmlTag("code", md.sanitizeText(node.content || ""), {
					class: node.lang ? "language-" + node.lang : undefined
				})
			)
		}
	},
	blockQuote: {
		// > single
		// 
		// > multi
		// > line
		// 
		// >>> until
		// the end of the
		// message
		...md.defaultRules.blockQuote,
		match: firstOnLineMatchRule(/^(> [^\n]+(\n>( [^\n]+)?)*|>>> [\s\S]*)\n?/),
		parse(capture, parse, state) {
			return {
				content: parse(capture[1].replace(/^>(>>)? ?/gm, ""), state)
			}
		}
	},
	html: {
		order: md.defaultRules.blockQuote.order,
		match: source => new RegExp(
			"^(" +
				"<(" + safeTags.join("|") + ")(\\s+[^\\s>]+)*\\s*\\/?>|" + // open tag
				"<\\/(" + safeTags.join("|") + ")\\s*>" + // close tag
			")",
			"i"
		).exec(source),
		parse(capture) {
			return {
				content: capture[1]
			}
		},
		html(node) {
			return node.content;
		}
	},
	table: {
		...md.defaultRules.table,
		match: firstOnLineMatchRule(/^ *(\|.+)\n *\|( *[-:]+[-| :]*)\n((?: *\|.*(?:\n|$))*)\n*/),
	}
}

declare global {
	function markdown(md: string): {html: string, mentions: string[]};
	function escapeHTML(text: string): string;
}
function compileRules() {
	const parser = md.parserFor(markdownRules);
	const output: Output<string> = md.outputFor(markdownRules, "html");
	
	window.markdown = (md: string) => {
		const ast = parser(md, {
			disableAutoBlockNewlines: true,
			inline: true,
			_list: true,
		});
		const html = output(ast);
		// grab mentions
		const ment = mentions === undefined ? [] : [...mentions.values()];
		// reset mentions
		mentions = undefined;
		return {
			html,
			mentions: ment,
		};
	}
}
compileRules();

window.escapeHTML = md.sanitizeText;
