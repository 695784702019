import { h } from "../../dom";
import { getSettingBool } from "../../getsetting";

export function setAvatar(element: HTMLElement, avatar: string|undefined, text: string) {
	if(avatar && getSettingBool("show-media")) {
		element.dataset.type = "image";
		element.appendChild(h("img", {
			src: avatar,
			alt: text,
			loading: "lazy",
		}));
	} else {
		element.dataset.type = "text";
		element.appendChild(h("div", {
			className: "avatar-text",
		})).textContent = text.substring(1, 2);
	}
}
