import { Component } from "../../component";
import { h } from "../../dom";
import { Event } from "../../types/event";
import { setReply } from "../../ui/reply";

export function* EventActions(event: Event, refs: Event[]): Component {
	const el = h("div", {className: "event-actions"});
	
	function entry(name: string, action: () => void) {
		el.appendChild(h("input", {type: "button", value: name, onclick: action}));
	}
	
	entry("source", () => alert(JSON.stringify(event, null, 2)));
	if(event.id !== undefined) {
		entry("mark read", () => api("/v1/account/channels/" + encodeURIComponent(event.channel.substring(1)) + "/read_marker", {read_marker: event.id}, "PUT"));
	}
	entry("react", () => {
		const key = prompt("React with:");
		if(!key) {
			return;
		}
		const existing = refs.find(n => n.type === "react" && n.user === login.user && n.content?.reaction === key);
		if(existing === undefined) {
			send(event.channel, "r.react", {reaction: key}, event.id);
		} else {
			send(event.channel, "r.delete", {}, existing.id);
		}
	});
	
	entry("reply", () => {
		setReply(event);
	});
	
	if(event.user === login.user) {
		entry("delete", () => {
			if(confirm("Are you sure you want to delete this message?")) {
				send(event.channel, "r.delete", {}, event.id);
			}
		});
	}
	
	while(yield el);
}
