import { h } from "../../dom";
import { insertHTML, insertText } from "../../html";

export function renderBody(el: HTMLElement, body: any) {
	const bdi = el.appendChild(h("bdi"))
	if(typeof(body?.["text/html"]) === "string") {
		el.className += " formatted";
		insertHTML(bdi, body["text/html"]);
	} else if(typeof(body?.["text/plain"]) === "string") {
		insertText(bdi, body["text/plain"]);
	}
}
