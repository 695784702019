import { id } from "../dom";
import { displayName } from "../eventutil";
import { getSettingBool } from "../getsetting";
import { Event } from "../types/event";

const el = id("typing")!;

type TypingUsers = {[user: string]: {event: Event, timeout: number}};

// keep track of who is typing in each channel
let typing: {[channel: string]: TypingUsers} = Object.create(null);

// get a list of typing events for the channel
export function getTyping(channel?: string): Event[] {
	if(channel === undefined) {
		return [];
	}
	
	return Object.values(typing[channel] || Object.create(null)).map(n => n.event);
}

// get the actual "x is typing..." text
export function getTypingText(channel?: string): string {
	const list = getTyping(channel);
	function name(n: number) {
		return displayName(list[n]) || list[n].user;
	}
	
	switch(list.length) {
		case 0: return "";
		case 1: return name(0) + " is typing...";
		case 2: return name(0) + " and " + name(1) + " are typing...";
		case 2: return name(0) + ", " + name(1) + ", and " + name(2) + " are typing...";
		case 3: return name(0) + ", " + name(1) + ", " + name(2) + ", and " + name(3) + " are typing...";
		default: return "Several people are typing...";
	}
}

// set it on the element
function update() {
	if(getSettingBool("show-typing")) {
		el.textContent = getTypingText(channel);
	}
}

update();
channelSwitcher.on("switch", update);

ws.on("message", ms => {
	if(ms.type === "event" && ms.data.type === "e.typing" && ms.data.user !== login.user) {
		// add this event to the typing list for the channel
		const ch = ms.data.channel;
		if(!(ch in typing)) {
			typing[ch] = Object.create(null);
		}
		
		const u = ms.data.user;
		// if there's already a typing event for this user, this one overrides it
		if(u in typing[ch]) {
			clearTimeout(typing[ch][u].timeout);
		}
		const time = typeof(ms.data.content?.expires) === "number" ? ms.data.content.expires : 5000;
		
		typing[ch][u] = {
			event: ms.data,
			timeout: setTimeout(() => {
				// after the timeout, delete the record and update
				delete typing[ch][u];
				update();
			}, time),
		};
		
		update();
	}
});
