import { Component } from "../component";
import { h } from "../dom";
import { Invite, inviteFormat } from "../types/invite";
import { setAvatar } from "../ui/common/avatar";
import { List } from "./List";

export function* InviteItem(invite: Invite, deleteInvite: (id: string) => void): Component {
	const avatar = h("div", {className: "avatar"});
	setAvatar(avatar, typeof(invite.summary?.icon) === "string" ? invite.summary.icon : undefined, invite.channel);
	
	let name = "";
	if(typeof(invite.summary?.name) === "string") {
		name = invite.summary.name;
	}
	
	const el = h("li", {}, [
		avatar,
		h("div", {className: "invite-text"}, [
			name ? h("div", {className: "invite-name"}, [h("bdi", {}, [name])]) : undefined,
			h("div", {className: "invite-address"}, [invite.channel]),
		]),
		h("div", {className: "invite-code"}, [invite.invite]),
		h("input", {type: "button", value: "Join", async onclick() {
			await send(invite.channel, "u.join", {invite: invite.invite});
			deleteInvite(invite.id);
			location.hash = "#" + invite.channel.substring(1);
		}}),
		h("input", {type: "button", value: "Reject", onclick() {
			deleteInvite(invite.id);
		}}),
	]);
	
	while(yield el) {}
}

export type InviteListUpdate =
	| {type: "mount"}
	| {type: "unmount"}

export function* InviteList(scrollParent: HTMLElement, onlist?: (invites: Invite[], load?: () => void) => void): Component<InviteListUpdate> {
	const deleteInvite = async (id: string) => {
		await api("/v1/account/invites/" + encodeURIComponent(id), undefined, "DELETE");
		list.next({type: "delete", data: id});
	};
	
	const list = List({
		title: "invites",
		loadmore: "Load more invites",
		endpoint: "/v1/account/invites",
		classPrefix: "invite-list",
		compare() { return -1 },
		format: inviteFormat,
		idKey: "id",
		Item: InviteItem,
		args: [deleteInvite],
		scrollParent,
		onlist(data, load) {
			onlist?.(data.map(n => n.data), load);
		},
	});
	
	const el = list.next().value!;
	
	const unsub = ws.on("message", ms => {
		if(ms.type === "invite") {
			list.next({type: "item", data: ms.data});
		}
	});
	
	let signal: InviteListUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "mount" || signal.type === "unmount") {
			list.next(signal);
		}
	}
	
	list.next();
	unsub();
}
