import { Component } from "../component";
import { append, h } from "../dom";
import { array, invalid } from "../format";
import { ChannelMembership } from "../types/channel";
import { setAvatar } from "../ui/common/avatar";

export type ChannelEntryUpdate =
	| {type: "update", data: ChannelMembership}
	| {type: "select", data: boolean}

export function* ChannelEntry(channel: ChannelMembership): Component<ChannelEntryUpdate> {
	let link: HTMLAnchorElement;
	const el = h("li", {
		title: channel.channel,
	}, [
		(link = h("a", {className: "channel-entry-link", href: "#" + channel.channel.substring(1)}, [])),
	]);
	
	function update(ch: ChannelMembership, initial?: boolean) {
		const old = channel;
		channel = ch;
		
		if(initial || ch.unread !== old.unread) {
			if(ch.unread) {
				el.dataset.unread = "true";
			} else {
				delete el.dataset.unread;
			}
		}
		
		if(initial || ch.mentions !== old.mentions) {
			if(ch.mentions === 0) {
				delete el.dataset.mentions;
			} else {
				el.dataset.mentions = ch.mentions.toString();
			}
		}
		
		if(
			initial ||
			old.summary?.icon !== ch.summary?.icon ||
			old.summary?.name !== ch.summary?.name
		) {
			link.innerHTML = "";
			
			const avatar = h("div", {class: "avatar"});
			setAvatar(avatar, typeof(channel.summary?.icon) === "string" ? channel.summary.icon : undefined, channel.channel);
			
			let name: string = "";
			if(typeof(channel.summary?.name) === "string") {
				name = channel.summary.name;
			}
			
			if(channel.channel.startsWith("#dm:")) {
				const v = invalid(channel.summary?.users, array("string"));
				if(v === undefined) {
					name = (channel.summary.users as string[]).filter(n => n !== login.user).join(", ");
				}
			}
			
			append(link, [
				avatar,
				h("div", {className: "channel-entry-text"}, [
					name ? h("div", {className: "channel-entry-name"}, [h("bdi", {}, [name])]) : undefined,
					h("div", {className: "channel-entry-address"}, [channel.channel]),
				]),
			]);
		}
	}
	
	update(channel, true);
	
	let signal: ChannelEntryUpdate|undefined;
	while(signal = yield el) {
		if(signal.type === "update") {
			update(signal.data);
		}
		
		if(signal.type === "select") {
			if(signal.data) {
				el.dataset.selected = "true";
			} else {
				delete el.dataset.selected;
			}
		}
	}
}
