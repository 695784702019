import { Component } from "../component";
import { ChannelList, ChannelListEvents } from "../components/ChannelList";
import { h, id } from "../dom";
import { array, check } from "../format";
import { keyboardShortcuts } from "../keyboard";
import { getSettingBool } from "../getsetting";
import { ChannelMembership } from "../types/channel";

const element = id("channels")!;

// which category does a channel belong to
function getCategory(channel: string) {
	const localpart = channel.split("@")[0].substring(1);
	const index = localpart.indexOf(":");
	
	if(index < 1) {
		return "";
	} else {
		return localpart.substring(0, index);
	}
}

// whether the channel fits in the category - undefined catches everything
function matches(channel: string, category: string|undefined) {
	if(category === undefined) {
		return true;
	}
	
	const c = getCategory(channel);
	return category === c;
}

// creates the elements and component for a category
function addCategory(cat: string|undefined): Category {
	let obj: Category = {
		channels: [],
	} as unknown as Category; // temporary
	
	const comp = ChannelList(cat, cat === "dm" || cat === undefined ? "recent" : undefined, (channels, load) => {
		// mainly for the channel switcher
		obj.channels = channels;
		obj.load = load;
	});
	
	let node: Node;
	if(cat === undefined) {
		node = element.appendChild(comp.next().value!);
	} else {
		const closed = localStorage.getItem("swerve-setting-category-" + cat + "-closed") === "true";
		
		const el = element.appendChild(h("details", {open: !closed, className: "category"}, [
			h("summary", {}, [cat || "Home"]),
			comp.next().value!,
		]));
		node = el;
		
		// persist the open/closed state across reloads
		el.addEventListener("toggle", () => {
			localStorage.setItem("swerve-setting-category-" + cat + "-closed", (!el.open).toString());
		});
	}
	
	comp.next({type: "mount"});
	
	obj = {
		...obj,
		cat,
		comp,
		node,
	};
	return obj;
}

type Category = {
	cat: string|undefined,
	comp: Component<ChannelListEvents>,
	node: Node,
	channels: ChannelMembership[],
	load?: () => void,
};

// if the user has turned off categories, resolves immediately to undefined
// otherwise, fetches a category list (and prepends "" for uncategorized)
let c: Promise<(string|undefined)[]>;
let categories: Category[]|undefined;

function setup() {
	// reset if needed
	if(categories) {
		element.innerHTML = "";
		categories.forEach(n => n.comp.next());
		categories = undefined;
	}
	
	if(getSettingBool("categories")) {
		c = api("/v1/account/categories")
			.then(check<string[]>(array("string")))
			.then(cats => [""].concat(cats));
	} else {
		c = Promise.resolve([undefined]);
	}
	
	c.then(cats => {
		categories = cats.sort().map(cat => {
			return addCategory(cat);
		});
	});
}

setup();

ws.on("message", async ms => {
	if(ms.type === "channel") {
		if(categories === undefined) {
			return;
		}
		let cat = categories.find(cat => matches(ms.data.channel, cat.cat));
		if(!cat) { // if it's in a new category, add it
			cat = addCategory(getCategory(ms.data.channel));
			categories.push(cat);
			
			// make sure the new category is in the right order
			categories.sort((a, b) => (
				a.cat === undefined || b.cat === undefined || // won't happen but just in case
				a.cat > b.cat ? 1 :
					(a.cat < b.cat ? -1 : 0)
			));
			const index = categories.indexOf(cat);
			if(index >= 0) {
				const next = categories[index + 1]?.node || null;
				element.insertBefore(cat.node, next);
			}
		} else {
			cat.comp.next({type: "channel", data: ms.data});
		}
	}
	
	if(ms.type === "event") {
		// figure out if the user has left the channel
		let leave = false;
		leave ||= ms.data.type === "u.leave" && ms.data.user === login.user;
		leave ||= ms.data.type === "u.kick" && ms.data.content?.user === login.user;
		if(!leave) {
			return;
		}
		
		// tell the corresponding category
		await c;
		let cat = categories?.find(cat => matches(ms.data.channel, cat.cat));
		if(!cat) {
			return;
		}
		cat.comp.next({type: "channeldel", data: ms.data.channel});
	}
});

// reset on reconnect
ws.on("reconnect", () => {
	setup();
});

let pendingchannel: string|undefined;
function relative(n: number): string|undefined {
	const ch = pendingchannel || channel;
	
	let cat: Category|undefined;
	if(ch !== undefined) {
		cat = categories?.find(cat => matches(ch!, cat.cat));
	}
	if(!cat) { // no current category - fall back to the first channel in the first category
		return categories?.[0]?.channels?.[0]?.channel;
	}
	
	let index = cat.channels.findIndex(n => n.channel === ch!);
	if(index < 0) { // the channel isn't in this category? fall back
		return categories?.[0]?.channels?.[0]?.channel;
	}
	
	let next = cat.channels[index + n]?.channel;
	if(!next) { // not in the list
		if(n > 0 && cat.load) { // maybe it isn't loaded yet?
			cat.load();
			return;
		} else { // move on to the next category
			const nextcat = categories?.[categories.indexOf(cat) + n];
			if(nextcat) {
				next = nextcat.channels[n > 0 ? 0 : nextcat.channels.length - 1]?.channel; // the first or last entry, depending on n
				if(!next && n > 0 && nextcat.load) { // if *that* doesn't exist and going down, try loading
					nextcat.load();
				}
			}
		}
	}
	
	return next;
}

channelSwitcher.on("switch", () => {
	categories?.forEach(n => n.comp.next({type: "select", data: channel}));
});

// buffered channel switch - mashing alt+down should only switch once you're done
let timeout: number|undefined;
function setChannel(ch: string) {
	pendingchannel = ch;
	
	categories?.forEach(n => n.comp.next({type: "select", data: ch}));
	if(timeout !== undefined) {
		clearTimeout(timeout);
	}
	timeout = setTimeout(() => {
		timeout = undefined;
		location.hash = ch;
		pendingchannel = undefined;
	}, 150);
}

keyboardShortcuts["alt+ArrowUp"] = () => {
	const next = relative(-1);
	if(next) {
		setChannel(next);
	}
}

keyboardShortcuts["alt+ArrowDown"] = () => {
	const next = relative(1);
	if(next) {
		setChannel(next);
	}
}

export function getChannelFromSidebar(channel: string): ChannelMembership|undefined {
	const cat = categories?.find(cat => matches(channel, cat.cat));
	if(!cat) {
		return;
	}
	return cat.channels.find(n => n.channel === channel);
}
