import { id } from "../dom";
import { getSettingBool, getUserLocal } from "../getsetting";
import { clearReply, reply } from "./reply";

const textarea = id("textarea")! as HTMLTextAreaElement;
const form = id("sendbar")! as HTMLFormElement;
const sendbutton = id("sendbutton")! as HTMLInputElement;

function textareaValue(text: string) {
	textarea.value = text;
	textarea.dispatchEvent(new InputEvent("input"));
}

form.onsubmit = ev => {
	ev.preventDefault();
	
	if(textarea.value === "") {
		return;
	}
	
	sendmessage(textarea.value).then(() => {
		textareaValue("");
		clearReply();
	});
}

async function sendmessage(text: string) {
	if(channel === undefined) {
		return;
	}
	
	let content: any = {
		body: {
			"text/plain": text,
		}
	};
	
	const html = markdown(text);
	
	if(html.html !== escapeHTML(text)) {
		content.body["text/html"] = html.html;
	}
	
	if(html.mentions.length) {
		content.mentions = html.mentions;
	}
	
	if((await getUserLocal("mention-reply")) && reply !== undefined) {
		content.mentions = (content.mentions || []).concat([reply.user]);
	}
	
	return await send(channel, "m.message", content, reply?.id);
}

let lastTyping = 0;
textarea.addEventListener("keydown", function(e) {
	// typing notifications
	const now = Date.now();
	if(
		channel !== undefined && // a channel is focused
		e.key &&
		e.key.length === 1 && // send typing notifications when letters are pressed (not things like Shift or LeftArrow)
		now - lastTyping > 2000 // if it's been a bit since the last typing notif
	) {
		lastTyping = now;
		const ch = channel;
		getUserLocal("typing").then(val => { // are typing notifications enabled
			if(val) {
				send(ch, "e.typing", {expires: 5000});
			}
		})
	}
	
	if(
		e.key === "Enter" &&
		(e.shiftKey !== true)//(localStorage.getItem("swerve-setting-shift-enter") !== "true"))
	) { // send on enter (but not shift+enter, unless the setting is on)
		e.preventDefault();
		
		sendbutton.click();
	}
	
	if(e.key === "Tab") {
		e.preventDefault();
		
		// insert \t
		const start = this.selectionStart;
		const end = this.selectionEnd;
		this.value = this.value.substring(0, start) + "\t" + this.value.substring(end);
		this.selectionStart = this.selectionEnd = start + 1;
	}
});
