import { Component } from "../component";
import { h } from "../dom"
import { check } from "../format";
import { InviteList } from "./InviteList";

export type AddChannelUpdate =
	| {type: "mount"}
	| {type: "unmount"}

export function* AddChannel(): Component<AddChannelUpdate> {
	async function oncreate(this: HTMLFormElement, ev: globalThis.Event) {
		ev.preventDefault();
		
		const localpart = this.elements.namedItem("localpart") as HTMLInputElement;
		
		api("/v1/channels", {localpart: localpart.value})
			.then(check<{channel: string}>({channel: "string"}))
			.then(ch => window.location.hash = ch.channel);
	}
	
	async function onjoin(this: HTMLFormElement, ev: globalThis.Event) {
		ev.preventDefault();
		
		const channel = this.elements.namedItem("channel") as HTMLInputElement;
		const invite = this.elements.namedItem("invite") as HTMLInputElement;
		
		send(channel.value, "u.join", {invite: invite.value})
			.then(ch => window.location.hash = ch.channel);
	}
	
	const el = h("div", {id: "add-channel-page"}, [
		h("div", {id: "add-channel-forms"}, [
			h("form", {
				onsubmit: oncreate,
			}, [
				h("input", {
					type: "text",
					placeholder: "localpart",
					name: "localpart",
					pattern: "^[a-zA-Z0-9\\-\\.:=_]{1,64}$",
				}),
				h("input", {type: "submit", value: "Create channel"}),
			]),
			h("form", {
				onsubmit: onjoin,
			}, [
				h("input", {
					type: "text",
					placeholder: "Channel",
					name: "channel",
					pattern: "^#[a-zA-Z0-9\\-\\.:=_]{1,64}@.*$",
				}),
				h("input", {type: "text", placeholder: "Invite code", name: "invite"}),
				h("input", {type: "submit", value: "Join channel"}),
			]),
		]),
	]);
	
	let hasInvites = false;
	const invites = InviteList(el, (data, load) => {
		if(data.length > 0 && !hasInvites) {
			hasInvites = true;
			el.insertBefore(h("h3", {}, ["Invites:"]), inviteEl);
		}
	});
	const inviteEl = invites.next().value!;
	el.appendChild(inviteEl);
	
	while(yield el) {}
	
	invites.next();
}
