import { Component } from "../component";
import { append, h } from "../dom";
import { LoadingTrigger } from "./LoadingTrigger";
import { Event, eventFormat } from "../types/event";
import { EventUpdate, createEvent } from "./events/index";
import { check } from "../format";
import { Paginated, paginated } from "../types/paginated";
import { getSettingBool } from "../getsetting";
import { ChannelMembership } from "../types/channel";
import { keyboardShortcuts } from "../keyboard";

export type EventTimelineMessage =
	| { type: "mount" }
	| { type: "unmount" }
	| { type: "event", data: Event }
	| { type: "channel", data: ChannelMembership }

type EventEntry = {event: Event, node?: Node, comp?: Component<EventUpdate>};
type EventCompEntry = Required<EventEntry>;

export function* EventTimeline(channel: string): Component<EventTimelineMessage> {
	const el = h("div", {
		className: "messages-container",
		"data-bottom": "true",
	});
	const list = h("ol", {
		className: "messages",
	});
	
	const loadmorebutton = h("input", {type: "button", onclick: load, value: "Load more messages"});
	const topLoader = LoadingTrigger({
		parent: el,
		className: "messages-loader",
		ontrigger: () => {
			if(first || getSettingBool("scroll-load")) {
				load();
			}
		},
		children: [loadmorebutton],
	});
	
	let atBottom = true;
	const bottomLoader = LoadingTrigger({
		parent: el,
		className: "messages-bottom",
		ontrigger: markRead,
		initial: true,
		onstate(state) {
			atBottom = state;
			
			// set `data-bottom="true"` when scrolled to the bottom
			if(state) {
				el.dataset.bottom = "true";
			} else {
				delete el.dataset.bottom;
			}
		},
	});
	
	const spacer = h("div", {className: "messages-spacer"});
	append(el, [
		spacer,
		topLoader.next().value!,
		list,
		bottomLoader.next().value!,
	]);
	
	// existing event components
	let events: {[id: number]: EventEntry} = Object.create(null);
	let ends: {f?: EventCompEntry, b?: EventCompEntry} = Object.create(null);
	// references
	let refs: {[id: number]: Event[]} = Object.create(null);
	
	let readMarker: number|undefined;
	
	function setReadMarker(d: number|undefined) {
		if(readMarker === d) {
			return;
		}
		
		const oldReadMarker = readMarker;
		readMarker = d;
		console.log("read_marker", oldReadMarker, "->", readMarker);
		
		// update events with new read status
		for(const key in events) {
			const id = parseInt(key);
			if(isNaN(id)) {
				continue;
			}
			const evt = events[id];
			if(!evt?.comp) {
				continue;
			}
			
			if(
				(oldReadMarker === undefined && readMarker !== undefined && id > readMarker) || // undefined -> number, all after number need to be updated
				(oldReadMarker !== undefined && readMarker === undefined && id > oldReadMarker) || // number -> undefined, all after number need to be updated
				(oldReadMarker !== undefined && readMarker !== undefined && ( // number -> number, events between the two need to be updated
					id > Math.min(oldReadMarker, readMarker) && id <= Math.max(oldReadMarker, readMarker)
				))
			) {
				const unread = readMarker === undefined ? false : id > readMarker;
				evt.comp.next({type: "unread", data: unread});
			}
		}
	}
	
	function addEvent(event: Event, before: Node|null = null, append?: boolean) {
		// create the component
		const ev = createEvent(event, event.id === undefined ? [] : (refs[event.id] || []).slice());
		
		// add to refs
		if(event.id !== undefined && event.ref !== undefined) {
			refs[event.ref] = (refs[event.ref] || []);
			if(append) {
				refs[event.ref].push(event);
			} else {
				refs[event.ref].unshift(event);
			}
		}
		
		if(event.id !== undefined) {
			events[event.id] = {
				event,
			};
		}
		
		if(ev) {
			// add to document
			const el = ev.next().value!;
			
			if(!append) {
				ev.next({type: "unread", data: readMarker !== undefined && event.id !== undefined && event.id > readMarker});
			}
			
			list.insertBefore(el, before);
			return events[event.id!] = {
				event,
				node: el,
				comp: ev,
			};
		}
	}
	
	// if there are multiple replies to the same event, it may be fetched multiple times
	let fetchrefcache: {[id: number]: Promise<Event>} = Object.create(null);
	function fetchref(id: number) {
		if(id in fetchrefcache) {
			return fetchrefcache[id];
		}
		return fetchrefcache[id] = api("/v1/channels/" + encodeURIComponent(channel.substring(1)) + "/events/" + id.toString())
			.then(check<Event>(eventFormat));
	}
	
	function loadref(ev: Event, comp: Component<EventUpdate>) {
		if(ev.ref === undefined) {
			return;
		}
		fetchref(ev.ref)
			.then(evt => {
				comp.next({type: "refto", data: evt});
			});
	}
	
	// loading state
	let first = true;
	let loading = false;
	let cursor: number|undefined;
	let done = false;
	
	// page backwards
	async function load() {
		// make sure two loads aren't done at once
		if(loading || done) {
			return;
		}
		first = false;
		loading = true;
		topLoader.next("disable");
		
		// make sure the view isn't completely scrolled up - that disables scroll anchoring
		if(el.scrollTop < 2) {
			el.scrollTop = 2;
		}
		
		try {
			const params = new URLSearchParams();
			params.append("limit", "64");
			if(cursor !== undefined) {
				params.append("cursor", cursor.toString());
			}
			
			console.log("events loading start");
			const result = await api("/v1/channels/" + encodeURIComponent(channel.substring(1)) + "/events?" + params.toString())
				.then(check<Paginated<Event, number>>(paginated("number", eventFormat)));
			
			const oldAtBottom = atBottom;
			
			// add new events to the beginning
			let before: Node|null = list.firstChild;
			let added: EventCompEntry[] = [];
			for(let i = result.data.length - 1; i >= 0; i--) {
				let el = addEvent(result.data[i], before);
				if(el !== undefined) {
					added.push(el);
					before = el.node;
					
					// tell the first event what its previous event is
					if(ends.b) {
						ends.b.comp.next({type: "prev", data: el.event});
					}
					ends.b = el;
					
					// if this is updating an empty list, set the last event to this one
					if(!ends.f) {
						ends.f = el;
					}
				}
			}
			
			// for each added element, see if it needs a ref loaded
			for(const el of added) {
				if(el.event.ref !== undefined) {
					if(el.event.ref in events) { // it's already in there
						el.comp.next({type: "refto", data: events[el.event.ref].event});
					} else {
						loadref(el.event, el.comp);
					}
				}
			}
			
			// make sure a page scrolled to the bottom stays scrolled to the bottom
			if(oldAtBottom) {
				el.scrollTop = el.scrollHeight;
			}
			
			cursor = result.cursor_prev;
			done = result.cursor_prev === undefined;
			if(done) { // disable subsequent loads
				spacer.dataset.beginning = "true";
				topLoader.next("hide");
			}
			console.log("events loading end");
		} finally {
			loading = false;
			topLoader.next("enable");
		}
	}
	
	function markRead() {
		if(
			ends.f?.event?.id !== undefined &&
			(readMarker === undefined || ends.f.event.id > readMarker) &&
			getSettingBool("read-scroll")
		) {
			console.log("marking latest event as read");
			api("/v1/account/channels/" + encodeURIComponent(channel.substring(1)) + "/read_marker", {read_marker: ends.f.event.id}, "PUT");
		}
	}
	
	// the scroll anchor is only set to a child element when not scrolled completely to the top
	// this keeps the view from shifting when new events are loaded at the top
	el.addEventListener("scroll", () => {
		if(loading && el.scrollTop < 2) {
			console.log('adjusting scroll');
			el.scrollTop = 2;
		}
	}, {capture: true});
	
	// if it's scrolled to the bottom, keep it at the bottom when the element is resized
	let observer: ResizeObserver|undefined;
	if(window.ResizeObserver) {
		observer = new ResizeObserver(() => {
			if(atBottom) {
				el.scrollTop = el.scrollHeight;
			}
		});
		observer.observe(el);
	}
	
	let cachedScroll: number|undefined;
	
	let signal: EventTimelineMessage|undefined;
	while(signal = yield el) {
		// immediately after switching back to this channel
		if(signal.type === "mount") {
			// restore saved scroll value - otherwise the element will be scrolled all the way to the top
			if(cachedScroll !== undefined) {
				el.scrollTop = cachedScroll;
				cachedScroll = undefined;
			}
			bottomLoader.next("enable");
			topLoader.next("enable");
			
			keyboardShortcuts["Escape"] = () => {
				if(ends.f?.event?.id !== undefined) {
					api("/v1/account/channels/" + encodeURIComponent(channel.substring(1)) + "/read_marker", {read_marker: ends.f.event.id}, "PUT");
					setReadMarker(ends.f.event.id);
				}
			}
		}
		
		// right before switching away
		if(signal.type === "unmount") {
			// save scroll value
			cachedScroll = el.scrollTop;
			
			bottomLoader.next("disable");
			topLoader.next("disable");
		}
		
		if(signal.type === "event") {
			// when a new event comes in for this channel
			if(cursor === undefined) {
				// make sure loading previous events starts at this one
				cursor = signal.data.id;
			}
			const added = addEvent(signal.data, null, true);
			if(added) {
				// update the last event in the list
				if(ends.f) {
					added.comp.next({type: "prev", data: ends.f.event});
				}
				ends.f = added;
				
				if( // set the read marker if the user is looking at the bottom
					added.event.id !== undefined &&
					(readMarker === undefined || added.event.id > readMarker) &&
					document.hasFocus() &&
					atBottom
				) {
					api("/v1/account/channels/" + encodeURIComponent(channel.substring(1)) + "/read_marker", {read_marker: ends.f.event.id}, "PUT");
					setReadMarker(ends.f.event.id);
				}
			}
			
			// if this refers to an event
			if(signal.data.id !== undefined && signal.data.ref !== undefined) {
				// and that event is already rendered
				if(signal.data.ref in events) {
					const evt = events[signal.data.ref];
					
					if(added) {
						// let this event know about the event it's referring to
						added.comp.next({type: "refto", data: evt.event});
					}
					
					if(signal.data.type === "r.delete") {
						// removing an event
						if(evt.comp && evt.node) {
							list.removeChild(evt.node);
							evt.comp.next();
						}
						
						// telling all events that referred to it that it's gone
						// a delete event that gets rendered shouldn't display the event that it deleted
						if(evt.event.id !== undefined && refs[evt.event.id]) {
							refs[evt.event.id].forEach(n => {
								if(n.id !== undefined) {
									events[n.id]?.comp?.next({type: "refto"});
								}
							});
						}
						// if *that* event referred to something, let *that* one know (e.g. deleting a reaction)
						if(evt.event.ref !== undefined && evt.event.ref in events) {
							events[evt.event.ref].comp?.next({type: "refdel", data: evt.event});
							
							// and remove the deleted event from refs
							if(refs[evt.event.ref]) {
								refs[evt.event.ref] = refs[evt.event.ref].filter(n => n.id !== evt.event.id);
							}
						}
						
						delete events[signal.data.ref];
					} else {
						// let it know there's a new ref
						evt.comp?.next({type: "ref", data: signal.data});
					}
				} else if(added) {
					// load the event from the api
					loadref(signal.data, added.comp);
				}
			}
		}
		
		if(signal.type === "channel") {
			setReadMarker(signal.data.read_marker);
		}
	}
	
	topLoader.next();
	bottomLoader.next();
	Object.values(events).forEach(n => n.comp?.next());
	observer?.disconnect();
}
