import { Event } from "../types/event";

export function getDisplayNameProfile(profile: any): string|undefined {
	if(typeof(profile?.name) === "string") {
		return profile.name;
	}
}

export function getDisplayName(ev: Event): string|undefined {
	return getDisplayNameProfile(ev.profile);
}

export function getDisplayNameFallback(ev: Event): string {
	const d = getDisplayName(ev);
	if(d === undefined) {
		return ev.user;
	}
	return d;
}
